var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Locations"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Checklists","sub-heading":"An index of checklists performed by team members."}})],1),_c('column',{staticClass:"is-5"},[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search for checklists..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",attrs:{"working":_vm.running_filters},on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(Object.values(_vm.$route.query).length)?_c('action-button',{staticClass:"is-rounded",on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),_c('action-button',{staticClass:"is-rounded",on:{"click":function($event){_vm.filtering = !_vm.filtering}}},[_c('icon',{attrs:{"icon":"sliders"}})],1)],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('action-button',{staticClass:"is-small is-rounded",attrs:{"working":_vm.downloading,"left-icon":"download"},on:{"click":_vm.downloadExcel}},[_vm._v(" Excel ")])],1)],1),_c('checklist-filters',{directives:[{name:"show",rawName:"v-show",value:(_vm.filtering),expression:"filtering"}],ref:"filters",on:{"filter":_vm.updateAdvancedFilters}}),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.checklists.data),function(checklist){return _c('div',{key:checklist.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{staticClass:"has-text-weight-bold is-block",attrs:{"to":{
              name: 'checklist-manager',
              params: {
                checklist: checklist.uuid
              }}}},[_vm._v(" "+_vm._s(checklist.checklist.name)+" "),(checklist.area)?_c('span',{staticClass:"has-text-weight-light"},[_vm._v("- "+_vm._s(checklist.area.name))]):_vm._e()]),_c('small',{staticClass:"has-text-grey"},[_vm._v(" Active "+_vm._s(_vm._f("asCalendar")(checklist.updated_at))+". Performed by "+_vm._s(checklist.user.full_name)+" on "),_c('router-link',{attrs:{"to":{
                  name: 'work-order-manager',
                  params: {
                    workOrder: checklist.work_order_uuid
                  }
                }}},[_vm._v("Work Order #"+_vm._s(checklist.work_order.number))])],1)],1),_c('column',{staticClass:"is-2 is-flex is-align-items-center is-justify-content-end"},[_c('inspectable-name',{attrs:{"with-icon":"","type":checklist.inspectable_type,"inspectable":checklist.inspectable}})],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-end"},[_c('checklist-videos-count',{attrs:{"answers":checklist.videos_count}})],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-end"},[_c('checklist-images-count',{attrs:{"answers":checklist.answers}})],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-end"},[_c('circular-progress-bar',{attrs:{"tooltip":"Current progress for this checklist","total":checklist.answers.length,"total-complete":checklist.answers.filter(answer => answer.complete).length,"backgroundColor":"#212127"}})],1),_c('column',{staticClass:"is-1 is-flex is-align-items-center is-justify-content-end"},[_c('outcome-tag',{attrs:{"status":checklist.status}})],1)],1)],1)}),0)])],1),(_vm.checklists.data.length)?_c('pager',{attrs:{"pageable":_vm.checklists,"context":"Checklist","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.checklists.data.length,"heading":"There are no checklists available for display."}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <loader v-bind="{ loading }" text="Loading Locations">
    <columns>
      <column>
        <page-heading
          heading="Checklists"
          sub-heading="An index of checklists performed by team members."
        />
      </column>
      <column class="is-5">
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search for checklists...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  :working="running_filters"
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button 
                  v-if="Object.values($route.query).length"
                  @click="clearFilters"
                  class="is-rounded">
                  <icon icon="times"/>
                </action-button>
                <action-button class="is-rounded" @click="filtering = !filtering">
                  <icon icon="sliders" />
                </action-button>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>

    <columns>
      <column>
        <action-button 
          @click="downloadExcel"
          :working="downloading"
          class="is-small is-rounded" 
          left-icon="download">
          Excel
        </action-button>
      </column>
    </columns>

    <checklist-filters 
      v-show="filtering"
      ref="filters"
      @filter="updateAdvancedFilters"
    />

    <columns>
      <column>
        <div class="index-rows">
          <div v-for="checklist in checklists.data" :key="checklist.id" class="box is-marginless">
            <columns>
              <column>
                <router-link class="has-text-weight-bold is-block" :to="{
                name: 'checklist-manager',
                params: {
                  checklist: checklist.uuid
                }}">
                    {{ checklist.checklist.name }} <span class="has-text-weight-light" v-if="checklist.area">- {{ checklist.area.name }}</span>
                </router-link>
                <small class="has-text-grey">
                  Active {{ checklist.updated_at | asCalendar }}. Performed by {{ checklist.user.full_name }} on <router-link :to="{
                    name: 'work-order-manager',
                    params: {
                      workOrder: checklist.work_order_uuid
                    }
                  }">Work Order #{{ checklist.work_order.number }}</router-link>
                </small>
              </column>
              <column class="is-2 is-flex is-align-items-center is-justify-content-end">
                <inspectable-name
                  with-icon
                  :type="checklist.inspectable_type"
                  :inspectable="checklist.inspectable"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <checklist-videos-count 
                  :answers="checklist.videos_count"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <checklist-images-count 
                  :answers="checklist.answers"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <circular-progress-bar
                  tooltip="Current progress for this checklist"
                  :total="checklist.answers.length"
                  :total-complete="checklist.answers.filter(answer => answer.complete).length"
                  backgroundColor="#212127"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <outcome-tag 
                  :status="checklist.status"
                />
              </column>
            </columns>
          </div>
        </div>
      </column>
    </columns>
    <pager 
      v-if="checklists.data.length" 
      :pageable="checklists" 
      context="Checklist" 
      jump-controls 
      @nav="goToPage" 
    />

    <no-items-to-display
      :item-count="checklists.data.length"
      heading="There are no checklists available for display."
    />
  </loader>
</template>
<script>
import { get } from '@/api/request'
import ChecklistFilters from './partials/Filters.vue'
import { common as backend } from '@/api'
import download from 'downloadjs'

const base_filters = {
  search: ''
}
export default {

  components: {
    ChecklistFilters
  },

  data: () => ({
    loading: true,
    filtering: false,
    running_filters: false,
    downloading: false,
    filters: { ...base_filters },
    checklists: {
      data: []
    },
  }),

  created() {
    if(this.$route.query) {
      this.filters = {...this.filters, ...this.$route.query}
    }
    this.loadChecklists()
  },

  methods: {
    loadChecklists() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.checklists = data
        this.loading = false
        this.running_filters = false
      }, () => {
        this.loading = false
        this.running_filters = false
      }, { params: this.filters })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    checklistProgressPercentage(answers) {
      const completed = answers.filter(answer => answer.complete).length
      const total = answers.length
      if(completed === 0) return 0
      return ((completed / total) * 100).toFixed(0)
    },
    checklistProgress(answers) {
      return `${answers.filter(answer => answer.complete).length}/${answers.length}`
    },
    runSearch() {
      this.running_filters = true
      this.$router.push({
        name: 'checklists',
        query: this.filters
      })
    },
    clearFilters() {
      this.$refs.filters.clearFilters()
      this.running_filters = false
      this.filters = { ...base_filters }
      this.$router.push({
        name: 'checklists',
      })
    },
    updateAdvancedFilters(filters) {
      this.filters = { ...this.filters, ...filters }
    },
    downloadExcel() {
      this.downloading = true
      backend.downloadFile(`/v1/checklists/download-xls`, ({ data }) => {
        download(data, `checklists.xlsx`, 'xlsx')
        this.downloading = false
      }, () => {
        this.downloading = false
      }, this.filters)
    }
  },

  watch: {
    '$route': 'loadChecklists'
  }

}
</script>